<!-- 详情与编辑、添加都放同一页 -->
<template>
  <div>
    <!-- 基本信息 -->
    <div class="pad-container">
      <p>活动信息</p>
      <el-form class="mt-24" label-width="120px" :model="act_form" :rules="rules" ref="act_form">
        <el-form-item label="活动名称" prop="activityName">
          <el-input
            class="form-input"
            maxlength="15"
            show-word-limit
            placeholder="请输入活动标题"
            v-model="act_form.activityName"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属门店" prop="storeName">
          <el-input v-model="act_form.storeName" disabled class="form-input"></el-input>
        </el-form-item>
        <el-form-item label="活动形式" prop="activityType">
          <el-select placeholder="请选择活动形式" class="search-input form-input" v-model="act_form.activityType" :disabled="actId!=''">
            <el-option
              v-for="(item,index) in goodsTypeOptions"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动头图" prop="picture">
          <UploadPics
            :uploadType="'main'"
            :imgLimit="1"
            :imgSize="'750'"
            :imgsPreview="act_form.picture"
            @getImgData="getImgData"
          />
        </el-form-item>
        <!-- 优惠限购 -->
        <template v-if="act_form.activityType==0">
            <el-form-item label="活动时间" prop="startTime">
              <el-date-picker
                v-model="activeTime"
                type="datetimerange"
                start-placeholder="活动开始日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy- MM-dd HH:mm:ss"
                end-placeholder="活动结束日期"
                :default-time="['00:00:00','23:59:59']"
                @change="changeActiveTime">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="添加活动商品" prop="activityRelations">
              <!-- <el-button icon="el-icon-plus" type="primary">选择商品</el-button> -->
              <el-button
                      type="primary"
                      plain
                      @click="selectGoods(act_form.activityRelations)"
                    >{{act_form.activityRelations&&act_form.activityRelations.length>=1?'重新选择商品':'添加商品'}}</el-button>
              <el-button icon="el-icon-plus" type="text" @click="importGoods">导入活动商品</el-button>
              <el-button icon="el-icon-plus" type="text" @click="setConditionAll" :disabled="this.act_form.activityRelations.length===0">批量设置限购条件</el-button>
             
              <el-table
                    class="mt-24 member-table"
                    :data="goodsTableInfo()"
                    width="70%"
                    height="500px"
                    ref="goodsTable"
                  >
                    <el-table-column label="排序">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.sort" placeholder="请输入排序号"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column label="商品条形码">
                      <template slot-scope="scope">
                        <span>{{scope.row.goodsCode}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="商品名称" width="200px">
                      <template slot-scope="scope">
                        <span>{{scope.row.goodsName}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="一级类目">
                      <template slot-scope="scope">
                        <span>{{scope.row.classifyName}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="二级类目">
                      <template slot-scope="scope">
                        <span>{{scope.row.secondClassifyName}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="实价">
                      <template slot-scope="scope">
                        <span>{{scope.row.actualPrice}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="原价">
                      <template slot-scope="scope">
                        <span>{{scope.row.originalPrice}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="库存">
                      <template slot-scope="scope">
                        <span>{{scope.row.stock}}</span>
                      </template>
                    </el-table-column>
                    <!-- new table-->
                    <el-table-column label="活动价(元)">
                      <template slot-scope="scope">
                        <span >{{scope.row.activityPrice}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="活动库存">
                      <template slot-scope="scope">
                        <span>{{scope.row.activityStock}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="新用户首单价(元)">
                      <template slot-scope="scope">
                        <span>{{scope.row.firstPrice}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="商品减运(元)">
                      <template slot-scope="scope">
                        <span>{{scope.row.freightPrice}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="限购条件">
                      <template slot-scope="scope" v-if="scope.row">

                        <span v-if="scope.row.restrictionConditions===0">
                          不限购,<br>
                          新用户首单限购{{scope.row.fristRestrition}}件
                        </span>
                        <span v-if="scope.row.restrictionConditions===1">
                          每单限购{{scope.row.activityRestriction}}件,<br>
                          新用户首单限购{{scope.row.fristRestrition}}件
                        </span>
                        <span v-if="scope.row.restrictionConditions===2">
                          每日限购{{scope.row.activityRestriction}}件,<br>
                          新用户首单限购{{scope.row.fristRestrition}}件
                        </span>
                        <span v-if="scope.row.restrictionConditions===3">
                          活动期限购{{scope.row.activityRestriction}}件,<br>
                          新用户首单限购{{scope.row.fristRestrition}}件
                        </span>
                      </template>
                    </el-table-column>
                    <!-- new table end -->
                    <el-table-column label="操作" align="center" fixed="right" width="200">
                      <template slot-scope="scope">
                        <div class="flx-row ali-c">
                          <span
                            class="ml-10 tip-text-info"
                            @click="editGoods(scope.row,(pageNum-1)*pageSize+scope.$index)"
                          >编辑</span>
                          <span
                            class="ml-10 tip-text-info"
                            @click="setGoods(scope.row,(pageNum-1)*pageSize+scope.$index)"
                          >设置限购条件</span>
                          <span
                            class="ml-10 tip-text-delete"
                            @click="deleteGoods((pageNum-1)*pageSize+scope.$index)"
                          >删除</span>
                        </div>
                      </template>
                    </el-table-column>
              </el-table>
              <Pagination
                :total="total"
                :pageNum="pageNum"
                :pageSize="pageSize"
                :pageSizes="pageSizes"
                @syncPageData="syncPageData"
              />
            </el-form-item>
        </template>
        <!-- 满减运费 -->
        <template v-if="act_form.activityType==1">
          <el-form-item  label="优惠方式" prop="activityAllowance.allowanceType">
            <el-radio-group v-model="act_form.activityAllowance.allowanceType" class="obj_radio">
              <el-radio :label="0">优惠一次</el-radio>
              <el-radio :label="1">循环优惠</el-radio>
              <el-radio :label="2">每日优惠</el-radio>
            </el-radio-group>
            <template v-if="act_form.activityAllowance.allowanceType==2">
              <div>
                单个用户每日优惠 <el-input-number v-model="act_form.activityAllowance.numberOffers" controls-position="right" :min="1"></el-input-number> 次
              </div>
            </template>
          </el-form-item>
          <el-form-item  label="满减门槛" prop="activityAllowance.standardAmount">
              订单满 <el-input-number v-model="act_form.activityAllowance.standardAmount" controls-position="right" :min="0" :precision="2"></el-input-number> 元 <span class="c-9"> 此处填写的金额为商品总价(包含打包费)</span>
          </el-form-item>
          <el-form-item  label="减免运费" prop="activityAllowance.freight">
              减免 <el-input-number v-model="act_form.activityAllowance.freight" controls-position="right" :min="0" :precision="2"></el-input-number> 元 
          </el-form-item>
          <el-form-item label="活动时间" prop="startTime">
              <el-date-picker
                v-model="activeTime"
                type="datetimerange"
                start-placeholder="活动开始日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                end-placeholder="活动结束日期"
                :default-time="['00:00:00','23:59:59']"
                @change="changeActiveTime">
              </el-date-picker>
          </el-form-item>
          <el-form-item  label="参与人限制" prop="activityAllowance.activityPersonnel">
            <el-radio-group v-model="act_form.activityAllowance.activityPersonnel" class="obj_radio">
              <el-radio :label="0">全部用户</el-radio>
              <el-radio :label="1">新用户</el-radio>
              <el-radio :label="2">老用户</el-radio>
              <el-radio :label="3">金卡会员（含首月折扣卡）</el-radio>
              <el-radio :label="4">铂卡会员</el-radio>
              <el-radio :label="5">钻卡会员</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="互斥">
              <el-button type="primary" icon="el-icon-plus" @click="showMutexDialog">选择活动</el-button>
              <span class="c-9"> ( 选中的活动与此活动优惠不同享，自动选择减免金额高的优惠；无选中互斥活动表示此活动可与其他活动的优惠叠加使用 ) </span>
          </el-form-item>
          <el-table
                    class="mt-24 member-table"
                    :data="mutexActiveData"
                    width="70%"
                    ref="mutexActiveTable"
                  >
                    <el-table-column label="活动名称">
                      <template slot-scope="scope">
                        <span>{{scope.row.activityName}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="活动形式">
                      <template slot-scope="scope">
                        <span>{{scope.row.typeName}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="状态">
                      <template slot-scope="scope">
                        <span>{{scope.row.statusName}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" fixed="right" width="200">
                      <template slot-scope="scope">
                        <div class="flx-row ali-c">
                          <span
                            class="ml-10 tip-text-delete"
                            @click="deleteActive(scope.$index)"
                          >删除</span>
                        </div>
                      </template>
                    </el-table-column>
            </el-table>
        </template>
        
      </el-form>
      <div class="flx-row ali-c js-fe mt-50">
        <el-button plain class="form-button" @click="cancel">取消</el-button>
        <el-button class="form-button" type="primary" @click="submit">确定</el-button>
      </div>
    </div>
    <!-- 选择活动商品 -->
    <GoodsDialog
      ref="GoodsDialog"
      :tableHeader="tableHeader"
      :hasTab="true"
      :tabList="tabList"
      :selected="true"
      :storeId="storeId"
      :searchArr="searchArr"
      @getSelectedList="getSelectedList"
    ></GoodsDialog>
    <!-- 编辑活动商品 -->
    <GoodsActiveDiolog
      ref="GoodsActiveDiolog"
      @getData="setGoodsActive"
    >
    </GoodsActiveDiolog>
    <!-- 设置限购条件 -->
    <GoodsActivityCondition
      ref="GoodsActivityCondition"
      @getData="setGoodsCondition"
      @getAllData="setGoodsConditionAll"
    ></GoodsActivityCondition>
    <MutexActiveDialog
     ref="MutexActiveDialog"
     :storeId="storeId"
     @getMutexActive="getMutexList"
    ></MutexActiveDialog>
    <!-- 导入商品列表 -->
    <ImportActGoodDialog
      ref="ImportActGoodDialog"
      :storeId="storeId"
      @uploadGoods="getUploadGoods"
      >
    </ImportActGoodDialog>
    <!-- 解决活动商品冲突 -->
    <ImportConflictDialog
      ref="ImportConflictDialog"
      @getConflictResult="getConflictResult"
    ></ImportConflictDialog>
  </div>
</template>
<script>
import UploadPics from "@/components/UploadPics";
import GoodsDialog from "@/components/GoodsDialog";
import GoodsActiveDiolog from "@/components/GoodsActiveDiolog";
import GoodsActivityCondition from "@/components/GoodsActivityCondition";
import MutexActiveDialog from '@/components/MutexActiveDialog'
import ImportActGoodDialog from '@/components/ImportActGoodDialog'
import ImportConflictDialog from "@/components/ImportConflictDialog"
import Pagination from "@/components/Pagination"
import {
  addActivity,
  getActivityDetailBack,
} from "@/api/market/activity";

export default {
  components: {
    UploadPics,
    GoodsDialog,
    GoodsActiveDiolog,
    GoodsActivityCondition,
    MutexActiveDialog,
    ImportActGoodDialog,
    ImportConflictDialog,
    Pagination
  },
  data() {
    return {
      radio: 1,
      searchArr: [
        {
          name: "goodsCodes",
          value: "",
          placeholder: "请输入商品条形码"
        },
        {
          name: "goodsName",
          value: "",
          placeholder: "请输入商品名称"
        }
      ],
      tabList: [
        {
          label: "商超商品",
          name: "1",
          url: "/shd-operation/lrGoodsInfo/queryGoodsInfo",
          method: "post",
          key: "goodsId",
          type: "1"
        },
        {
          label: "生鲜商品",
          name: "2",
          url: "/shd-operation/lrGoodsInfo/queryGoods",
          method: "post",
          key: "goodsId",
          type: "2"
        }
      ],
      goodsUrl: "/goods-service/goods/info/list", // 商品列表url
      shopOptions: [],
      goodsTypeOptions:[
        { id: 0, name: "优惠限购" },
        { id: 1, name: "满减运费" }
      ],
      activeTime:[], //活动时间
      act_form: {
        storeName: "",
        activityName: "",
        picture: "",
        activityType:0,
        activityRelations: [],
        startTime:'',
        endTime:'',
        
        activityAllowance:{
          allowanceType:0,
          numberOffers:0,
          standardAmount:0,
          freight:0,
          activityPersonnel:0,
        },
      },
      rules: {
        activityName: [
          { required: true, message: "活动名称不能为空", trigger: "blur" }
        ],
        picture: [
          { required: true, message: "活动图片不能为空", trigger: "blur" }
        ],
        activityRelations: [
          { required: true, message: "活动商品不能为空", trigger: "blur" }
        ],
        activityType:[
          { required: true, message: "请选择活动类型" }
        ],
        startTime:[
          { required: true, message: "请选择活动时间",trigger: "blur" }
        ],
        'activityAllowance.allowanceType':[
          { required: true, message: "请选择优惠方式" }
        ],
        'activityAllowance.standardAmount':[
          { required: true, message: "请输入商品总价",trigger: "blur" }
        ],
        'activityAllowance.freight':[
         { required: true, message: "请输入减免运费",trigger: "blur" }
        ],
        'activityAllowance.activityPersonnel':[
          { required: true, message: "请选择参与人" }
        ],
      }, 
      actId: "",
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 15, 20, 30],
      mainImgStr: "",
      firstFlag: false,
      id: null,
      selected_temp: [], // 全局勾选数组
      // 商品列表表头
      tableHeader: [
        {
          value: "商品条形码",
          key: "goodsCode"
        },
        {
          value: "商品名称",
          key: "goodsName"
        },
        {
          value: "一级类目",
          key: "classifyName"
        },
        {
          value: "二级类目",
          key: "secondClassifyName"
        },
        {
          value: "实价",
          key: "actualPrice"
        },
        {
          value: "库存",
          key: "stock"
        }
      ],
      storeId: "",
      goodsDetail:{},
      showConditionDialog:false, //设置条件弹窗
      mutexActiveData:[], //互斥活动列表
      act_data:[]
    };
  },
  mounted() {
    this.init(); 
  },
  filters: {
    goodsTypeFilter(val) {
      switch (val) {
        case 1:
          return "商品";
        case 2:
          return "优惠券";
        case 3:
          return "商兑换券";
        default:
          return "--";
      }
    }
  },
  methods: {
    // 删除分类
    delClassify(index) {
      this.isConfirm(this, () => {
        this.act_form.activityClassifies.splice(index, 1);
      });
    },
    // 添加分类
    addClassify() {
      let item = {
        activityClassifyName: "",
        picture: "",
        activityRelations: []
      };
      this.act_form.activityClassifies.push(item);
    },
    //   获取已勾选商品
    getSelectedList(data, index) {
      // this.act_form.activityClassifies[index].activityRelations = data;
      // this.$set(
      //   this.act_form.activityClassifies,
      //   index,
      //   this.act_form.activityClassifies[index]
      // );
      this.act_form.activityRelations = data;
      this.total = data.length
    },
    // 打开商品列表弹窗
    selectGoods(activityRelations) {
      let data = this.$refs.GoodsDialog;
      data.act_form.list = activityRelations;
      data.showGoods = true;
      // data.index = index;
      data.getGoods(true);
      data.selected_temp = JSON.parse(JSON.stringify(activityRelations));
    },
    // 获取详情id
    init() {
      let query = this.$route.query;
      this.actId = query.id || "";
      this.storeId = query.storeId;
      this.actId ? this.getActData() : "";
      this.act_form.storeName = this.$route.query.storeName;
      if(query.copy){
        this.assingData(query.copy)
      }
    },
    //获取活动详情
    getActData() {
      getActivityDetailBack({ id: this.actId }).then(res => {
        this.act_data = res.data.body;
        this.assingData(this.act_data)
        // this.mainImgStr = act_data.picture;
      });
    },
    assingData(data){
      this.act_form = data;
      this.act_form.activityRelations = []
      this.act_form.activityRelations = data.goods
      this.activeTime = [data.startTime,data.endTime]
      this.act_form.activityRelations = data.activityRelations
      this.total = data.goods.length
      this.mutexActiveData = data.activityMutexes.map(item=>{
        item.mutexActivity.status==0?item.mutexActivity.statusName="下架":item.mutexActivity.statusName="上架"
        item.mutexActivity.activityType=0?item.mutexActivity.typeName="优惠限购":item.mutexActivity.typeName="满减运费"
        return item.mutexActivity
      })
      this.act_form.activityMutexes =  data.activityMutexes.map(item=>{
        return {mutexActivityId: item.mutexActivityId}
      })
      this.$refs.MutexActiveDialog.selectActive = JSON.parse(
        JSON.stringify(
          this.mutexActiveData
        )
      );
    },
    // 改变活动时间
    changeActiveTime(val){
      if(val){
        this.act_form.startTime = val[0]
        this.act_form.endTime = val[1]
      }else{
        this.act_form.startTime =''
        this.act_form.endTime = ''
      }
    },
    // 删除已选商品
    deleteGoods(idx) {
      this.act_form.activityRelations.splice(idx, 1);
      this.$refs.GoodsDialog.selected_temp = JSON.parse(
        JSON.stringify(
          this.act_form.activityRelations
        )
      );
      this.total = this.act_form.activityRelations.length
      if(this.goodsTableInfo().length==0&&this.pageNum!=1){
        this.pageNum --
      }
    },
    // 编辑活动商品
    editGoods(goods,idx) {
      // this.$refs['GoodsActiveDiolog'].reset();
      let data= JSON.parse(JSON.stringify(goods))
      this.$refs['GoodsActiveDiolog'].showGoodsDetail = true
      this.$refs['GoodsActiveDiolog'].setData(data,idx)
    },
    // 设置商品的活动信息
    setGoodsActive(obj){
      let idx = obj.index
      let data = obj.data
      this.act_form.activityRelations[idx] = data
      this.act_form.activityRelations = Object.assign([],this.act_form.activityRelations)
    },
    // 显示活动商品限购条件
    setGoods(goods,idx) {
      this.$refs['GoodsActivityCondition'].showDialog = true
      this.$refs['GoodsActivityCondition'].isSetAll = false  //批量设置
      this.$refs['GoodsActivityCondition'].setData(goods,idx )
    },
    // 设置活动商品限购条件
    setGoodsCondition(obj){
      let idx = obj.index
      let data = obj.data
      this.act_form.activityRelations[idx].activityRestriction=data.activityRestriction
      this.act_form.activityRelations[idx].fristRestrition=data.fristRestrition
      this.act_form.activityRelations[idx].restrictionConditions= data.restrictionConditions
      this.act_form.activityRelations = Object.assign([],this.act_form.activityRelations)
    },
    // 批量设置限购条件
    setConditionAll(){
      this.$refs['GoodsActivityCondition'].showDialog = true
      this.$refs['GoodsActivityCondition'].isSetAll = true  //批量设置
      this.$refs['GoodsActivityCondition'].reset()
    },
    setGoodsConditionAll(data){
      this.act_form.activityRelations = this.act_form.activityRelations.map((item)=>{
        item.activityRestriction=data.activityRestriction
        item.fristRestrition=data.fristRestrition
        item.restrictionConditions= data.restrictionConditions
        return item
      })
      this.act_form.activityRelations = Object.assign([],this.act_form.activityRelations)
    },
    // 获取图片数据
    getImgData(imgData, type, params, index) {
      if (index) {
        this.act_form.activityClassifies[index].picture = imgData.mainImg;
      } else if (index == 0) {
        this.act_form.activityClassifies[0].picture = imgData.mainImg;
      } else {
        type == "main" ? (this.act_form.picture = imgData.mainImg) : "";
      }
    },
    // 返回
    cancel() {
      this.$router.go(-1);
    },
    // 提交表单-验证
    submit() {
      let validGoods = false
      
      this.$refs["act_form"].validate(valid => {
        if (!valid) {
          this.$message.warning("表单信息不完整（有误）");
          return;
        }
        if(this.act_form.activityType==0){
          if (this.act_form.activityRelations.length===0) {
            this.$message.warning("请添加活动商品");
            return ;
          }
          let actRel = this.act_form.activityRelations
          for(let i=0;i<actRel.length;i++){
            // if(!actRel[i].hasOwnProperty('lrGoodsActivityCondition')){
            //   this.$message.warning("请填写商品限购信息！");
            //   validGoods = true
            //   break ;
            // }
            if(
              !((actRel[i].restrictionConditions!==""&&actRel[i].restrictionConditions!==undefined&&actRel[i].restrictionConditions!==null)
              ||(!actRel[i].activityRestriction!==""&&actRel[i].activityRestriction!==undefined&&actRel[i].activityRestriction!==null)
              ||(!actRel[i].fristRestritionn!==""&&actRel[i].fristRestritionn!==undefined&&actRel[i].fristRestritionn!==null))){
              this.$message.warning("请输入限购条件后再提交");
              validGoods = true
              return false;
            }
            actRel[i].lrGoodsActivityCondition = {
              restrictionConditions:actRel[i].restrictionConditions,
              activityRestriction:actRel[i].activityRestriction,
              fristRestrition:actRel[i].fristRestrition
            }
            actRel[i].lrGoodsActivityPrice = {
              activityPrice:actRel[i].activityPrice,
              firstPrice:actRel[i].firstPrice,
              activityStock:actRel[i].activityStock,
              freightPrice:actRel[i].freightPrice
            }
          }
          if(!validGoods){
            this.submitForm();
          }
        }else{
           this.submitForm();
        }
        
        
      });
    },
    // 提交表单-提交
    submitForm() {
      // let method = "post";
      let data = JSON.parse(JSON.stringify(this.act_form));
      if (data.activityRelations && data.activityRelations.length > 0) {
        data.activityRelations.forEach(item => {
          delete item.goodsInfo;
          delete item.groupId;
          delete item.id;
        });
      }
      data.storeId = this.storeId;
      if (this.actId) {
        data.id = this.actId;
      }
      
      addActivity(data).then((res) => {
        if(res.data.code===200){
          this.$message.success("操作成功");
          this.$router.go(-1);
          return 
        }
        if(res.data.code===101){ //上传数据有冲突
          this.$refs.ImportConflictDialog.showDialog = true
          this.$refs.ImportConflictDialog.getData(res.data.body)
          this.$refs.ImportConflictDialog.setFormData(data);
        }
      }).catch((error)=>{
        console.log("这个是错误的数据",error)
      });  
    },
    // 互斥
    showMutexDialog(){
      this.$refs.MutexActiveDialog.showMutexActive = true
    },
    getMutexList(data){ //获取选择后的互斥活动的列表
      console.log(data)
      this.mutexActiveData = JSON.parse(JSON.stringify(data))
      this.act_form.activityMutexes = this.mutexActiveData.map(item => {return {'mutexActivityId':item.id}});
    },
    deleteActive(idx){ //删除互斥活动
      this.mutexActiveData.splice(idx, 1);
      this.act_form.activityMutexes = this.mutexActiveData.map(item => {return {'mutexActivityId':item.id}});
      this.$refs.MutexActiveDialog.selectActive = JSON.parse(
        JSON.stringify(
          this.mutexActiveData
        )
      );
    },
    getUploadGoods(data){//获取上传后的商品列表
      this.total = data.length
      this.act_form.activityRelations = data
    },
    importGoods(){ //导入活动商品
      this.$refs.ImportActGoodDialog.dialogVisible = true
      this.$refs.ImportActGoodDialog.goodsList = this.act_form.activityRelations
      this.$refs.ImportActGoodDialog.mergeGoodsList = []
    },
    getConflictResult(data){ //解决冲突后返回的列表
      if(data.length === 0){
         this.$message({
          message: '商品均保留原有的活动，请重新选择商品',
          type: 'warning'
        });
        this.act_form.activityRelations = []
        return 
      }
      this.act_form.activityRelations = data
      this.submit()
    },
    syncPageData(data) { //导入商品的分页
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
    goodsTableInfo(){
      let i = this.pageNum
      let s = this.pageSize
      return this.act_form.activityRelations.slice((i-1)*s,i*s)
    }
    
  }
};
</script>
<style lang="scss" scoped>
.tip-text-info {
  color: #409eff;
  cursor: pointer;
}
.el-avatar /deep/ img {
  width: 100%;
}
.style {
  width: 130px;
  height: 200px;
  background: #f1f1f1;
  padding: 10px;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  div {
    border: 1px solid #ccc;
    float: left;
    margin-bottom: 10px;
    background: #fff;
  }
}
.style1 div {
  width: 50px;
  height: 80px;
  &:nth-child(even) {
    margin-left: 10px;
  }
}
.style2 div {
  width: 30px;
  height: 50px;
  margin-left: 5px;
}
.flx-row /deep/ .el-radio {
  margin-right: 10px;
}
.el-form-item /deep/ .el-form-item {
  margin-bottom: 20px;
}
.del-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 10;
}
</style>